import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import './index.scss';

import { FaExternalLinkAlt, FaGithub, FaReact } from 'react-icons/fa';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import {TiWeatherCloudy} from 'react-icons/ti' 
import {
   SiExpress,
   SiMongodb,
   SiNodedotjs,
   SiHtml5,
   SiCss3,
   SiBootstrap,
   SiJavascript,
   SiSass,
   SiReact,
   SiFlask,
   SiPython,
   SiMui,
   SiPostgresql,
   SiJsonwebtokens,
   SiPrisma,
   SiAntdesign,
} from 'react-icons/si';
import { Zoom, Fade } from 'react-reveal';

import Preloader from '../../components/Preloader';

const projects = [
   {
      name: 'Do It Now',
      description: 'Personal Task Manager',
      cover: 'do-it-now',
      tags: [
         {
            name: 'React',
            icon: <FaReact />,
         },
         {
           name: 'Ant Design UI',
           icon: <SiAntdesign/>  
         },
         {
            name: 'Node.js',
            icon: <SiNodedotjs />,
         },
         {
            name: 'Express',
            icon: <SiExpress />,
         },
         {
            name: 'PostgreSQL',
            icon: <SiPostgresql />,
         },
         {
           name: 'Prisma',
             icon: <SiPrisma />, 
         },
         {
            name: 'JWT',
            icon: <SiJsonwebtokens />,
         },
      ],
      
      repo: 'https://github.com/Velogan-Boy/do-it-now',
      live: 'https://do-it-now.velan-projects.tech/',
   },
   {
      name: 'Auth App',
      description: 'A simple authentication app with Two-Factor Authentication',
      cover: 'auth',
      tags: [
         {
            name: 'React',
            icon: <FaReact />,
         },
         {
            name: 'Node.js',
            icon: <SiNodedotjs />,
         },
         {
            name: 'Express',
            icon: <SiExpress />,
         },
         {
            name: 'PostgreSQL',
            icon: <SiPostgresql />,
         },
         {
            name: 'JWT',
            icon: <SiJsonwebtokens />,
         },
      ],
      repo: 'https://github.com/Velogan-Boy/ctf-backend-task-api',
      live: 'https://auth-app.velan-projects.tech/',
   },

   {
      name: 'Cloud Guru',
      description: 'A Weather App made with React and Weather API',
      cover: 'cloud-guru',
      tags: [
         {
            name: 'React',
            icon: <FaReact />,
         },
         {
            name: 'Weather API',
            icon: <TiWeatherCloudy/>
            
         }
      ],
      repo: 'https://github.com/Velogan-Boy/cloud-guru',
      live: 'https://cloud-guru.velan-projects.tech/',
   },
   {
      name: 'Info Hunt',
      description: 'News Articles Search Engine - Latent Semantic Analysis based',
      cover: 'info-hunt',
      tags: [
         {
            name: 'Flask',
            icon: <SiFlask />,
         },
         {
            name: 'Python',
            icon: <SiPython />,
         },
         {
            name: 'HTML',
            icon: <SiHtml5 />,
         },
         {
            name: 'Bootstrap',
            icon: <SiBootstrap />,
         },
      ],
      repo: 'https://github.com/Velogan-Boy/info-hunt',
      live: 'https://info-hunt.onrender.com/',
   },
   {
      name: 'Diagno-AI-zer',
      description: 'Predicting diseases based on symptoms using different machine learning algorithms',
      cover: 'diagno-ai-zer',
      tags: [
         {
            name: 'Flask',
            icon: <SiFlask />,
         },
         {
            name: 'Python',
            icon: <SiPython />,
         },
         {
            name: 'HTML',
            icon: <SiHtml5 />,
         },
         {
            name: 'Bootstrap',
            icon: <SiBootstrap />,
         },
      ],

      repo: 'https://github.com/Velogan-Boy/diagno-ai-zer',
      live: 'https://diagno-ai-zer.onrender.com',
   },
   {
      name: 'Cinemax',
      description: 'Movie DB website that uses OMDB Api functionalities',
      cover: `cinemax`,
      tags: [
         {
            name: 'React',
            icon: <FaReact />,
         },
         {
            name: 'Node.js',
            icon: <SiNodedotjs />,
         },
         { name: 'Express', icon: <SiExpress /> },
         { name: 'MongoDB', icon: <SiMongodb /> },
      ],
      repo: 'https://github.com/Velogan-Boy/cinemax',
      live: 'https://cinemax.velan-projects.tech/',
   },
   {
      name: "Dorotics '23",
      description: 'Official Website for National Symposium on emerging technologies in Drones and Robotics',
      cover: 'dorotics',
      tags: [
         {
            name: 'React',
            icon: <SiReact />,
         },
         {
            name: 'Material UI',
            icon: <SiMui />,
         },
      ],
      repo: 'https://github.com/Velogan-Boy/dorotics-2023',
      live: 'https://dorotics.velan-projects.tech/',
   },
   {
      name: 'CSAU Website',
      description: 'Official Website for CSAU, a student club in my college',
      cover: 'csau',
      tags: [
         {
            name: 'React',
            icon: <SiReact />,
         },
      ],
      live: 'https://www.csau.in/',
   },

   {
      name: 'Fitify',
      description: 'A Fitness tracker website made with Bootstrap',
      cover: 'fitify',
      tags: [
         {
            name: 'HTML',
            icon: <SiHtml5 />,
         },
         {
            name: 'CSS',
            icon: <SiCss3 />,
         },
         {
            name: 'Bootstrap',
            icon: <SiBootstrap />,
         },
         {
            name: 'Javascript',
            icon: <SiJavascript />,
         },
      ],
      repo: 'https://github.com/Velogan-Boy/fitify-fitness-tracker',
      live: 'https://fitify.velan-projects.tech/',
   },

   {
      name: 'Bioverse',
      description: 'A Landing page for Green Products',
      cover: 'bioverse',
      tags: [
         {
            name: 'HTML',
            icon: <SiHtml5 />,
         },
         {
            name: 'CSS',
            icon: <SiCss3 />,
         },
      ],
      repo: 'https://github.com/Velogan-Boy/bioverse',
      live: 'https://bioverse.velan-projects.tech/',
   },
   {
      name: 'Swiggy Clone',
      description: 'A Swiggy Landing Page Clone made with Sass',
      cover: 'swiggy',
      tags: [
         {
            name: 'HTML',
            icon: <SiHtml5 />,
         },
         {
            name: 'CSS',
            icon: <SiCss3 />,
         },
         {
            name: 'Sass',
            icon: <SiSass />,
         },
      ],
      repo: 'https://github.com/Velogan-Boy/swiggy_clone',
      live: 'https://swiggy.velan-projects.tech/',
   }

   
];

function Projects() {
   const [load, upadateLoad] = useState(true);

   useEffect(() => {
      const timer = setTimeout(() => {
         upadateLoad(false);
      }, 500);

      return () => clearTimeout(timer);
   }, []);

   return (
      <>
         {load ? (
            <Preloader />
         ) : (
            <div className="projects-page">
               <Zoom>
                  <h1 className="sub-heading sub-heading--projects">
                     My<span>Projects</span>
                  </h1>
               </Zoom>

               <div className="project-container">
                  {projects.map((project, index) => (
                     <Fade key={index} delay={index * 300}>
                        <div className="project-item">
                           <div className="project-image">
                              <img src={`/projects-screenshots/${project.cover}.png`} alt="project" />
                           </div>
                           <div className="project-info">
                              <div className="project-name">
                                 <h2 className="project-title">{project.name}</h2>
                                 <div className="project-btns">
                                    <button className="project-btn">
                                       <a href={project.live} target="_blank">
                                          <FaExternalLinkAlt />
                                       </a>
                                    </button>
                                    <button className="project-btn">
                                       <a href={project.repo} target="_blank">
                                          {project.repo ? <FaGithub /> : ''}
                                       </a>
                                    </button>
                                 </div>
                              </div>
                              <div className="project-tags">
                                 {project.tags.map((tag, index) => (
                                    <span className="project-tag" key={index}>
                                       {tag.icon} {tag.name}
                                    </span>
                                 ))}
                              </div>
                           </div>
                           <div className="project-description">
                              <p>{project.description}</p>
                           </div>
                        </div>
                     </Fade>
                  ))}
               </div>

               <Link to="/resume" className="float-button">
                  <FontAwesomeIcon icon={faArrowRight} className="arrow" />
               </Link>
            </div>
         )}
      </>
   );
}

export default Projects;
