import React from 'react';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';

function Particle() {
   const particlesInit = async (main) => {
      await loadFull(main);
   };

   return (
      <Particles
         init={particlesInit}
         id="tsparticles"
         options={{
            particles: {
               number: {
                  value: 160,
                  density: {
                     enable: true,
                     value_area: 1500,
                  },
               },
               line_linked: {
                  enable: false,
                  opacity: 0.03,
               },
               move: {
                  direction: 'right',
                  speed: 0.1,
               },
               size: {
                  value: 1,
               },
               opacity: {
                  anim: {
                     enable: true,
                     speed: 1,
                     opacity_min: 0.05,
                  },
               },
            },

            retina_detect: true,
         }}
      />
   );
}

export default Particle;
