import React from 'react';

import {InfinitySpin } from 'react-loader-spinner'
 

import './index.scss';

function Preloader() {
   return (
      <div className="loader-page">
         <div className="loader-container">
            <InfinitySpin color="#f29544" className="loader" />
         </div>
      </div>
   );
}

export default Preloader;
