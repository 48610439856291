import React, { useEffect, useState } from 'react';
import { Bounce, Fade, Zoom, Slide, Rotate } from 'react-reveal';

import { Link } from 'react-router-dom';

import { faGithub, faLinkedin, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import './index.scss';

import Preloader from '../../components/Preloader';

import gif from '../../assets/images/giphy.gif';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const socialNav = [
   { icon: faLinkedin, link: 'https://www.linkedin.com/in/velmurugan-jeyakumar/' },
   {
      icon: faGithub,
      link: 'https://github.com/Velogan-Boy',
   },
   {
      icon: faTwitter,
      link: 'https://twitter.com/velogan_boy',
   },
   {
      icon: faInstagram,
      link: 'https://www.instagram.com/velogan_boy/',
   },
];

function About() {
   const [load, upadateLoad] = useState(true);

   useEffect(() => {
      const timer = setTimeout(() => {
         upadateLoad(false);
      }, 500);

      return () => clearTimeout(timer);
   }, []);
   return (
      <>
         {load ? (
            <Preloader />
         ) : (
            <div className="about-page">
               <Zoom>
                  <h1 className="sub-heading heading-desktop">
                     Let me <span>introduce </span>myself
                  </h1>
                  <h1 className="sub-heading heading-mobile">
                     About <span>me</span>
                  </h1>
               </Zoom>

               <div className="about-page-content">
                  <div className="avater-container">
                     <Zoom>
                        <div className="avatar">
                           <img src={gif} alt="Thats me" />
                        </div>
                     </Zoom>
                  </div>
                  <div className="about-page-text">
                     <Fade left delay={500}>
                        <p>
                           Hello there! I'm<span>Velmurugan</span>, you might know me as <span>Velan</span>, an enthusiastic Fullstack Web developer🧑‍💻 hailing from the
                           vibrant city of <img src="https://cdn-icons-png.flaticon.com/512/9906/9906480.png" width="16" alt="flag" /> Chennai, India. With a burning
                           passion for web development, I strive to create exceptional web apps and websites that combine both functionality and aesthetics. Currently, I
                           am pursuing 🎓 Bachelor's Engineering degree in Computer Science at the prestigious <span>College of Engineering, Guindy</span>, Anna
                           University. This educational journey has provided me with a solid foundation in programming and equipped me with the necessary technical skills
                           to excel in the field.
                        </p>
                     </Fade>

                     <Fade left delay={500}>
                        <p>
                           Throughout my academic journey, I have gained experience in various aspects of web development, including front-end and back-end development,
                           database management, and creating responsive and user-friendly interfaces. I am well-versed in programming languages such as{' '}
                           <span>HTML, CSS, JavaScript,</span> and have expertise in technologies like <span>React and Node.js.</span>I firmly believe in the power of
                           continuous learning and staying up-to-date with the latest technologies. As the web development landscape continues to evolve, I am committed
                           to sharpening my skills and embracing new challenges. By doing so, I aim to contribute effectively to the growth and success of any
                           organization I work with.
                        </p>
                     </Fade>

                     <Fade left delay={500}>
                        <p>
                           When I'm not coding, I love watching movies with epic heroes and cool stories. Now, when it comes to series, <span>"Modern Family"</span> is my
                           go-to comedy goldmine, and <span>Phil Dunphy</span> is the epitome of hilarious dad jokes! I try to embrace his humor in my everyday life, but
                           let's just say I'm more of a coding guru than a comedic genius. Music, especially those <span>soul-stirring soundtracks</span>, sets the mood
                           for my creativity. Books take me on exciting adventures to faraway places. Oh, let's not forget the <span>MCU!</span> I'm an unapologetic
                           fanboy, cheering for the superheroes as they save the day. And speaking of heroes, <span>Virat Kohli</span> is my cricket idol, and I never
                           miss a chance to witness the thrilling drama of <span>test cricket</span>. Cats hold a special place in my heart, and <span>Ronaldo</span>, my
                           buddy, adds purrfect joy to my life. So, if you need a web developer who's into movies, music, books, cricket, and pets, I'm your guy! Let's
                           create web magic together! 🚀
                        </p>
                     </Fade>
                  </div>

                  <Fade left delay={500}>
                     <div></div>
                  </Fade>

                  <div className="social-btn-container">
                     <ul>
                        {socialNav.map((item, index) => (
                           <li key={index}>
                              <a href={item.link} target="_blank" rel="noopener noreferrer">
                                 <FontAwesomeIcon icon={item.icon} />
                              </a>
                           </li>
                        ))}
                     </ul>
                  </div>
               </div>
               <Link to="/skills" className="float-button">
                  <FontAwesomeIcon icon={faArrowRight} className="arrow" />
               </Link>
            </div>
         )}
      </>
   );
}

export default About;
