import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import pdf from '../../assets/resume.pdf';
import { AiOutlineDownload } from 'react-icons/ai';
import { Document, Page, pdfjs } from 'react-pdf';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import './index.scss';

import Preloader from '../../components/Preloader';
import { Bounce, Fade, Zoom } from 'react-reveal';

const resumeLink = pdf;

function Resume() {
   const [width, setWidth] = useState(1200);
   const [load, upadateLoad] = useState(true);

   pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

   useEffect(() => {
      setWidth(window.innerWidth);
   }, []);

   useEffect(() => {
      const timer = setTimeout(() => {
         upadateLoad(false);
      }, 500);

      return () => clearTimeout(timer);
   }, []);

   return (
      <>
         {load ? (
            <Preloader />
         ) : (
            <div className="resume-page">
               <Zoom>
                  <h1 className="sub-heading resume-title">
                     <span>RESUME</span>
                  </h1>
               </Zoom>

               <Fade>
                  <button className="resume-download-button">
                     <AiOutlineDownload />{' '}
                     <a href={resumeLink} download>
                        Download CV
                     </a>
                  </button>
               </Fade>

               <Fade>
                  <div className="resume-container">
                     <Document className="document" file={resumeLink}>
                        <Page renderTextLayer={false} renderAnnotationLayer={true} pageNumber={1} scale={width > 786 ? 1.5 : 0.6} />
                     </Document>
                  </div>
               </Fade>

               <Fade>
                  <button className="resume-download-button">
                     <AiOutlineDownload />{' '}
                     <a href={resumeLink} download>
                        Download CV
                     </a>
                  </button>
               </Fade>

               <Link to="/contact" className="float-button">
                  <FontAwesomeIcon icon={faArrowRight} className="arrow" />
               </Link>
            </div>
         )}
      </>
   );
}

export default Resume;
