import { useState, useEffect } from 'react';
import { Analytics } from '@vercel/analytics/react';

import './App.scss';
import { Routes, Route } from 'react-router-dom';

import NavBar from './components/NavBar';

// pages
import Home from './pages/Home';
import About from './pages/About';
import Skills from './pages/Skills';
import Projects from './pages/Projects';
import Resume from './pages/Resume';
import Contact from './pages/Contact';

import Particles from './components/Particle';

function App() {
   useEffect(() => {
      const ele = document.getElementById('my-loader');
      if (ele) {
         // fade out
         setTimeout(() => {
            // remove from DOM
            ele.classList.add('available');
         }, 5000);
      }
   }, []);

   return (
      <>
         <div className="App">
            <Particles />
            <NavBar />
            <Routes>
               <Route path="/" element={<Home />} />
               <Route path="/about" element={<About />} />
               <Route path="/skills" element={<Skills />} />
               <Route path="/projects" element={<Projects />} />
               <Route path="/resume" element={<Resume />} />
               <Route path="/contact" element={<Contact />} />
            </Routes>
         </div>
         <Analytics />
      </>
   );
}

export default App;
