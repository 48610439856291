import { useEffect, useState, useRef } from 'react';

import emailjs from '@emailjs/browser';
import './index.scss';
import { Zoom, Bounce } from 'react-reveal';

import Preloader from '../../components/Preloader';

const Contact = () => {
   const [load, upadateLoad] = useState(true);

   useEffect(() => {
      const timer = setTimeout(() => {
         upadateLoad(false);
      }, 500);

      return () => clearTimeout(timer);
   }, []);

   const form = useRef();

   const sendEmail = (e) => {
      e.preventDefault();

      emailjs.sendForm('service_q03cm82', 'template_f9qsrmo', form.current, 'Ums9ixCgKXKJ7geFh').then(
         () => {
            alert('Message successfully sent!');
            window.location.reload(false);
         },
         (err) => {
            console.error('Send email failed: ', err);

            alert('Failed to send the message, please try again');
         }
      );
   };

   return (
      <>
         {load ? (
            <Preloader />
         ) : (
            <div className=" contact-page">
               <div className="text-zone">
                  <Zoom>
                     <h1 className="sub-heading contact-title">
                        Contact<span>me</span>
                     </h1>
                  </Zoom>

                  <Bounce>
                     <p>
                        I am interested in <span>internship opportunities </span>- especially in ambitious <span>startups</span>. However, if you have other request or
                        question, don't hesitate to contact me using the form below.
                     </p>
                  </Bounce>
                  <div className="contact-form">
                     <form autoComplete="off" ref={form} onSubmit={sendEmail}>
                        <ul>
                           <li className="half">
                              <input placeholder="Name" type="text" name="name" required />
                           </li>
                           <li className="half">
                              <input placeholder="Email" type="email" name="email" required />
                           </li>
                           <li>
                              <input placeholder="Subject" type="text" name="subject" required />
                           </li>
                           <li>
                              <textarea placeholder="Message" name="message" required></textarea>
                           </li>
                           <li>
                              <input type="submit" className="flat-button" value="Send" />
                           </li>
                        </ul>
                     </form>
                  </div>
               </div>

               <div className="map-wrap">
                  <iframe
                     src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d486.0855303032378!2d80.09182183071967!3d12.92800463496444!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52f585e4b1a093%3A0xa9526847bc175bb4!2sSHOBA%20HOMES!5e0!3m2!1sen!2sin!4v1659011977070!5m2!1sen!2sin"
                     style={{ border: 0, width: '100%', height: '100%' }}
                     referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
               </div>
            </div>
         )}
      </>
   );
};

export default Contact;
