import { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import Zoom from 'react-reveal/Zoom';

import Preloader from '../../components/Preloader';
import HomeHeroText from '../../components/HomeHeroText';
import illustration from '../../assets/images/home-illustration.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight} from '@fortawesome/free-solid-svg-icons';

import './index.scss';

const Home = () => {
   const [load, upadateLoad] = useState(true);

   useEffect(() => {
      const timer = setTimeout(() => {
         upadateLoad(false);
      }, 700);

      return () => clearTimeout(timer);
   }, []);

   return (
      <>
         {load ? (
            <Preloader />
         ) : (
            <div className="page">
               <div className="page-content">
                  <span className="tag tag--top">&lt;body&gt;</span>
                  <div className="hero-text-container">
                     <div className="text-zone">
                        <HomeHeroText />
                        <h2>PERN Stack Developer / UI.UX / Student </h2>

                        <Link to="/resume" className="flat-button">
                           {'See my Resume'}
                        </Link>
                     </div>
                  </div>
                  <span className="tag tag--bottom">&lt;/body&gt;</span>
               </div>

               <div className="hero">
                  <Zoom duration={1500} delay={100}>
                     <img src={illustration} className="hero-logo" alt="illustration" />
                  </Zoom>
               </div>

               <Link to="/about" className="float-button">
                  <FontAwesomeIcon icon={faArrowRight} className="arrow"  />
               </Link>
            </div>
         )}
      </>
   );
};

export default Home;
