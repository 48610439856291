import React from 'react';

import DesktopNavBar from './DesktopNavBar/DesktopNavBar';
import MobileNavBar from './MobileNavBar/MobileNavBar';

import './index.scss';

function NavBar() {
   return (
      <>
         <DesktopNavBar />
         <MobileNavBar />
      </>
   );
}

export default NavBar;
