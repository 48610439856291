import React, { useState, useEffect } from 'react';
import { Zoom, Fade } from 'react-reveal';
import { Link } from 'react-router-dom';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ImHtmlFive } from 'react-icons/im';
import { IoLogoCss3, IoLogoNodejs } from 'react-icons/io';
import { FaReact, FaSass, FaBootstrap } from 'react-icons/fa';
import {
   SiExpress,
   SiTailwindcss,
   SiMui,
   SiCplusplus,
   SiMongodb,
   SiCoffeescript,
   SiMysql,
   SiPhp,
   SiAntdesign,
   SiJavascript,
   SiMicrosoftazure,
   SiAmazonaws,
   SiVisualstudiocode,
   SiIntellijidea,
   SiGit,
   SiGithub,
   SiGitlab,
   SiPostman,
   SiVercel,
   SiHeroku,
   SiLinux,
   SiWindows,
   SiBrave,
   SiCanva,
   SiNotion,
   SiBabel,
   SiFigma,
   SiPostgresql,
   SiPython,
   SiC,
   SiFlask,
   SiNginx,
   SiRender,
   SiCloudflarepages,
   SiPrisma,
} from 'react-icons/si';
import { FaJava } from 'react-icons/fa';

import './index.scss';

import Preloader from '../../components/Preloader';

const languages = [
   {
      name: 'JavaScript',
      icon: <SiJavascript />,
   },
   {
      name: 'HTML5',
      icon: <ImHtmlFive />,
   },
   {
      name: 'CSS3',
      icon: <IoLogoCss3 />,
   },
   {
      name: 'Python',
      icon: <SiPython />,
   },
   {
      name: 'Java',
      icon: <FaJava />,
   },
   {
      name: 'C',
      icon: <SiC />,
   },
   {
      name: 'C++',
      icon: <SiCplusplus />,
   },
];

const frontend = [
   {
      name: 'React',
      icon: <FaReact />,
   },
   {
      name: 'SASS',
      icon: <FaSass />,
   },
   {
      name: 'TailwindCSS',
      icon: <SiTailwindcss />,
   },
   {
      name: 'AntDesign',
      icon: <SiAntdesign />,
   },
   {
      name: 'Bootstrap',
      icon: <FaBootstrap />,
   },
   {
      name: 'MaterialUI',
      icon: <SiMui />,
   },
];

const backend = [
   {
      name: 'NodeJS',
      icon: <IoLogoNodejs />,
   },
   {
      name: 'Express',
      icon: <SiExpress />,
   },
   {
      name: 'Flask',
      icon: <SiFlask />,
   },
   {
      name: 'Prisma',
      icon: <SiPrisma />,
   },
   {
      name: 'PostgreSQL',
      icon: <SiPostgresql />,
   },
   {
      name: 'MongoDB',
      icon: <SiMongodb />,
   },
   {
      name: 'MySQL',
      icon: <SiMysql />,
   },
];

const toolset = [
   {
      name: 'Git',
      icon: <SiGit />,
   },
   {
      name: 'Github',
      icon: <SiGithub />,
   },
   {
      name: 'Gitlab',
      icon: <SiGitlab />,
   },
   {
      name: 'VS Code',
      icon: <SiVisualstudiocode />,
   },

   {
      name: 'Postman',
      icon: <SiPostman />,
   },
   {
      name: 'NGINX',
      icon: <SiNginx />,
   },
   {
      name: 'AWS',
      icon: <SiAmazonaws />,
   },
   {
      name: 'IntelliJ IDEA',
      icon: <SiIntellijidea />,
   },
   {
      name: 'Babel',
      icon: <SiBabel />,
   },
   {
      name: 'Vercel',
      icon: <SiVercel />,
   },
   {
      name: 'Heroku',
      icon: <SiHeroku />,
   },
   {
      name: 'Render',
      icon: <SiRender />,
   },
   {
      name: 'CloudflarePages',
      icon: <SiCloudflarepages />,
   },
   {
      name: 'Linux',
      icon: <SiLinux />,
   },

   {
      name: 'Windows',
      icon: <SiWindows />,
   },
   {
      name: 'Brave',
      icon: <SiBrave />,
   },
   {
      name: 'Canva',
      icon: <SiCanva />,
   },
   {
      name: 'Figma',
      icon: <SiFigma />,
   },
   {
      name: 'Notion',
      icon: <SiNotion />,
   },
];

function Skills() {
   const [load, upadateLoad] = useState(true);

   useEffect(() => {
      const timer = setTimeout(() => {
         upadateLoad(false);
      }, 500);

      return () => clearTimeout(timer);
   }, []);

   return (
      <>
         {load ? (
            <Preloader />
         ) : (
            <div className="skills-page">
               <Zoom>
                  <h1 className="sub-heading sub-heading--skills">
                     My<span>Languages</span>
                  </h1>
               </Zoom>

               <ul className="skills-container">
                  {languages.map((skill, index) => (
                     <Fade key={index} delay={index * 100}>
                        <li key={index} className="skills-item">
                           <div className="skills-icon">{skill.icon}</div>
                           <div className="skills-name">{skill.name}</div>
                        </li>
                     </Fade>
                  ))}
               </ul>

               <Zoom>
                  <h1 className="sub-heading sub-heading--skills">
                     My<span>Frontend</span>Tech.
                  </h1>
               </Zoom>

               <ul className="skills-container">
                  {frontend.map((skill, index) => (
                     <Fade key={index} delay={index * 100}>
                        <li key={index} className="skills-item">
                           <div className="skills-icon">{skill.icon}</div>
                           <div className="skills-name">{skill.name}</div>
                        </li>
                     </Fade>
                  ))}
               </ul>

               <Zoom>
                  <h1 className="sub-heading sub-heading--skills">
                     My<span>Backend</span>Tech.
                  </h1>
               </Zoom>

               <ul className="skills-container">
                  {backend.map((skill, index) => (
                     <Fade key={index} delay={index * 100}>
                        <li key={index} className="skills-item">
                           <div className="skills-icon">{skill.icon}</div>
                           <div className="skills-name">{skill.name}</div>
                        </li>
                     </Fade>
                  ))}
               </ul>

               <Zoom>
                  <h1 className="sub-heading sub-heading--skills">
                     My<span>Toolset</span>
                  </h1>
               </Zoom>

               <ul className="skills-container">
                  {toolset.map((skill, index) => (
                     <Fade key={index} delay={index * 100}>
                        <li className="skills-item">
                           <div className="skills-icon">{skill.icon}</div>
                           <div className="skills-name">{skill.name}</div>
                        </li>
                     </Fade>
                  ))}
               </ul>

               <Link to="/projects" className="float-button">
                  <FontAwesomeIcon icon={faArrowRight} className="arrow" />
               </Link>
            </div>
         )}
      </>
   );
}

export default Skills;
