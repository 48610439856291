import React from 'react';

import { Link, NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faHome, faUser, faBook, faProjectDiagram,  faFile } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import logo from '../../../assets/images/portfolio-logo.png';

import './desktopNavBar.scss';

const navItems = [
   {
      className: '',
      link: '/',
      icon: faHome,
   },
   {
      className: 'about-link',
      link: '/about',
      icon: faUser,
   },
   {
      className: 'skills',
      link: '/skills',
      icon: faBook,
   },
   {
      className: 'projects',
      link: '/projects',
      icon: faProjectDiagram,
   },
   {
      className: 'resume',
      link: '/resume',
      icon: faFile,
   },
   {
      className: 'contact-link',
      link: '/contact',
      icon: faEnvelope,
   },
];

const socialNav = [
   { icon: faLinkedin, link: 'https://www.linkedin.com/in/velmurugan-jeyakumar/' },
   {
      icon: faGithub,
      link: 'https://github.com/Velogan-Boy',
   },
   {
      icon: faTwitter,
      link: 'https://twitter.com/velogan_boy',
   },
   {
      icon: faInstagram,
      link: 'https://www.instagram.com/velogan_boy/',
   },
];

function DesktopNavBar() {
   return (
      <div className="nav-bar">
         <Link className="logo" to="/">
            <img src={logo} alt="logo" />
         </Link>

         <nav>
            {navItems.map((item, index) => (
               <NavLink exact="true" key={index} activeclassname="active" className={item.className} to={item.link}>
                  <FontAwesomeIcon icon={item.icon} color="#9d9d9d"></FontAwesomeIcon>
               </NavLink>
            ))}
         </nav>

         <ul>
            {socialNav.map((item, index) => (
               <li key={index}>
                  <a className="link" href={item.link} target="_blank" rel="noreferrer">
                     <FontAwesomeIcon icon={item.icon} color="#9d9d9d" />
                  </a>
               </li>
            ))}
         </ul>
      </div>
   );
}

export default DesktopNavBar;
