import { useState, useEffect } from 'react';

import './index.scss';

const AnimatedLetters = ({ letterClass, strArray, idx }) => {
   return (
      <span>
         {strArray.map((char, i) => (
            <span key={i} className={`${letterClass} _${i + idx}`}>
               {char}
            </span>
         ))}
      </span>
   );
};

const HomeHeroText = () => {
   const [letterClass, setLetterClass] = useState('text-animate');

   const nameArray = ['', 'V', 'e', 'l', 'm', 'u', 'r', 'u', 'g', 'a', 'n'];
   const jobArray = ['W', 'e', 'b', ' ', 'd', 'e', 'v', 'e', 'l', 'o', 'p', 'e', 'r', '.'];

   useEffect(() => {
      setTimeout(() => {
         setLetterClass('text-animate-hover');
      }, 4000);
   }, []);

   return (
      <h1 className="hero-text">
         <span className={letterClass}>H</span>
         <span className={`${letterClass} _12`}>e</span>
         <span className={`${letterClass} _13`}>y,</span>
         <br />
         <span className={`${letterClass} _14`}>I</span>
         <span className={`${letterClass} _15`}>'m </span>
         <AnimatedLetters letterClass={letterClass} strArray={nameArray} idx={15} />
         <br />
         <AnimatedLetters letterClass={letterClass} strArray={jobArray} idx={22} />
      </h1>
   );
};

export default HomeHeroText;
