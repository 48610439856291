import React, { useState } from 'react';

import { Link, NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faEnvelope, faHome, faUser, faBook, faProjectDiagram, faFile } from '@fortawesome/free-solid-svg-icons';
import logoMobile from '../../../assets/images/portfolio-logo.png';


import './mobileNavBar.scss';

const navItems = [
   {
      name: 'Home',
      link: '/',
      icon: faHome,
   },
   {
      name: 'About',
      link: '/about',
      icon: faUser,
   },
   {
      name: 'Skills',
      link: '/skills',
      icon: faBook,
   },
   {
      name: 'Projects',
      link: '/projects',
      icon: faProjectDiagram,
   },
   {
      name: 'Resume',
      link: '/resume',
      icon: faFile,
   },
   {
      name: 'Contact',
      link: '/contact',
      icon: faEnvelope,
   },
];

function MobileNavBar() {
   const [checked, setChecked] = useState(false);

   const handleChange = () => {
      setChecked(!checked);
   };

   return (
      <div className="navbar-mobile">
         <Link className="logo" to="/">
            <img src={logoMobile} alt="logo" />
         </Link>

         <input type="checkbox" className="checkbox" checked={checked} onChange={handleChange} id="navi-toggle" />
         <label htmlFor="navi-toggle" className="btn">
            <span className="icon">&nbsp;</span>
         </label>

         <div className="bg">&nbsp;</div>

         <nav className="nav">
            <ul className="list">
               {navItems.map((navItem, index) => (
                  <li className="item" key={index}>
                     <NavLink exact="true" activeclassname="active" className="link" onClick={handleChange} to={navItem.link}>
                        <span>
                           <FontAwesomeIcon icon={navItem.icon}></FontAwesomeIcon>
                        </span>
                        {navItem.name}
                     </NavLink>
                  </li>
               ))}
            </ul>
         </nav>
      </div>
   );
}

export default MobileNavBar;
